import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import PrivateRoute from './PrivateRoutes';
import Login from '../components/authendication/Login';
import Forgotpassword from '../components/authendication/ForgotPassword';
import SetPasswordPage from '../components/authendication/SetPassword';
import ActivateAccount from '../components/authendication/AccountActivate';
import AllDone from '../components/authendication/allDone';
// import Sidebar from '../components/pages/Dashboard/Sidebar';
// import OrdersTabMenu from '../components/pages/Dashboard/OrdersTabMenu';
import Dashboard from '../components/screens/dashboard';
import AllOrders from '../components/screens/allOrders';
import StaffManagement from '../components/screens/StaffDetails/Index';
import CreateStaff from '../components/screens/StaffDetails/Users/Index';
import Userview from '../components/screens/StaffDetails/UsersView/Index';
import Settings from '../components/screens/Settings/index.js';
import NewOrders from '../components/screens/newOrders';
import OrderDtails from '../components/screens/orderDetails';
import ReadyToShip from '../components/screens/readyToShip';
import CourierIntegration from '../components/screens/courier';
import ChannelIntegration from '../components/screens/channel';
import Manifest from '../components/screens/manifest';
import InTransit from '../components/screens/inTransit';
import ManageServiceablePincode from '../components/screens/manageServicepincode';
import StorageDetails from '../components/screens/storageDetils';
import WareHouseAddress from '../components/screens/warehouse/address';
import HsnGst from '../components/screens/invoice/hsnGst';
import Billing from '../components/screens/billing/billing';
import ManagePlan from '../components/screens/billing/manageBilling';
import BillingPlans from '../components/screens/billing/viewPlan';

import DeliveryOrder from '../components/screens/delivery';
// import CancelledOrder from '../components/screens/cancelled';
// import HomePage from '../components/screens/homePage/';
import PermissionWrapper from './permissionWrapper';
import ProductDetails from '../components/screens/products/productDetails/index';
import CustomerDetails from '../components/screens/customers/customerDetails'
import Products from '../components/screens/products';
import Customers from '../components/screens/customers';
import ReturnRequest from '../components/screens/returns/returnRequests';
import Tracking from '../components/screens/tracking';
import NewReturn from '../components/screens/returns/newReturn';
import ReturnPickupSchedule from '../components/screens/returns/returnPickupScheduled';
import AllReturns from '../components/screens/returns/All';
import PageNotFound from '../components/screens/pageNotFound';
import TrackYourOrder from '../components/screens/trackYourOrder/trackYourOrder';
import ExotelIntegration from '../components/screens/exotelIntegrations';
import IntransitReturns from '../components/screens/returns/Intransit';
import ReturnReceived from '../components/screens/returns/returnReceived';
import SelfInstallation from '../components/screens/selfInstall/index';
import InvoiceTemplate from '../components/screens/invoiceTemplate';
import LabelTemplate from '../components/screens/labelTemplate';
import NdrOrders from '../components/screens/ndrOrders/index.js';
import CourierErrorLogs from '../components/screens/courierErrorLogs/index.js';
const RootApp = () => {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Login />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/loginwithToken/:token' element={<Login />} />
                    <Route path='/forgot-password' element={<Forgotpassword />} />
                    <Route path='/forgotPassword/:id' element={<SetPasswordPage />} />
                    <Route path='/activateAccount/:id' element={<ActivateAccount />} />
                    <Route path='/all-done' element={<AllDone />} />
                    <Route path='/:storeId/tracking' element={<TrackYourOrder />} />
                    <Route element={<PrivateRoute />}>
                        {/* <Route exact path='/home' element={<HomePage />} /> */}
                        <Route path='/dashboard' element={<PermissionWrapper moduleId={1}
                            child={(props) => <Dashboard {...props} />}
                        />} />
                        <Route path='/orders/all-orders' element={<PermissionWrapper module={"order"}
                            child={(props) => <AllOrders {...props} />}
                        />} />
                        <Route path='/orders/new' element={<PermissionWrapper module={"order"}
                            child={(props) => <NewOrders {...props} />}
                        />} />
                        <Route path='/orders/ready-to-ship' element={<PermissionWrapper module={"order"}
                            child={(props) => <ReadyToShip {...props} />}
                        />} />
                        <Route path='/orders/manifest' element={<PermissionWrapper module={"order"}
                            child={(props) => <Manifest {...props} />}
                        />} />
                        <Route path='/orders/in-transit' element={<PermissionWrapper module={"order"}
                            child={(props) => <InTransit {...props} />}
                        />} />
                        <Route path='/orders/delivery' element={<PermissionWrapper module={"order"}
                            child={(props) => <DeliveryOrder {...props} />}
                        />} />
                        <Route path='/orders/ndr' element={<PermissionWrapper module={"order"}
                            child={(props) => <NdrOrders {...props} />}
                        />} />

                        {/* <Route path='/orders/cancel' element={<PermissionWrapper module={"order"}
                            child={(props) => <CancelledOrder {...props} />}
                        />} /> */}
                        <Route path='/tracking' element={<PermissionWrapper module={"sideMenu"} moduleId={8}
                            child={(props) => <Tracking {...props} />}
                        />} />
                        <Route path='/returns/return-requests' element={<PermissionWrapper moduleId={0}
                            child={(props) => <ReturnRequest {...props} />}
                        />} />
                        <Route path='/returns/new-return' element={<PermissionWrapper moduleId={0}
                            child={(props) => <NewReturn {...props} />}
                        />} />
                        <Route path='/returns/return-pickup-schedule' element={<PermissionWrapper moduleId={0}
                            child={(props) => <ReturnPickupSchedule {...props} />}
                        />} />
                        <Route path='/returns/all-return' element={<PermissionWrapper moduleId={0}
                            child={(props) => <AllReturns {...props} />}
                        />} />
                        <Route path='/returns/in-transit' element={<PermissionWrapper moduleId={0}
                            child={(props) => <IntransitReturns {...props} />}
                        />} />
                        <Route path='/returns/return-received' element={<PermissionWrapper moduleId={0}
                            child={(props) => <ReturnReceived {...props} />}
                        />} />
                        <Route path='/settings' element={<PermissionWrapper module={"sideMenu"} moduleId={0}
                            child={(props) => <Settings {...props} />}
                        />} />
                        <Route path='/settings/staff-managemanet' element={<PermissionWrapper module={"sideMenu"} moduleId={0}
                            child={(props) => <StaffManagement {...props} />}
                        />} />
                        <Route path='/settings/staff-managemanet/create-staff' element={<PermissionWrapper module={"sideMenu"} moduleId={0}
                            child={(props) => <CreateStaff {...props} />}
                        />} />
                        <Route path='/settings/staff-managemanet/view-staff/:id' element={<PermissionWrapper module={"sideMenu"} moduleId={0}
                            child={(props) => <Userview {...props} />}
                        />} />
                        <Route path='/:mainpage/:page/order-details/:id' element={<PermissionWrapper module={"sideMenu"} moduleId={0}
                            child={(props) => <OrderDtails {...props} />}
                        />} />
                        <Route path='/settings/courier-integration' element={<PermissionWrapper module={"sideMenu"} moduleId={0}
                            child={(props) => <CourierIntegration {...props} />}
                        />} />
                        <Route path='/settings/exotel-ntegrations' element={<PermissionWrapper module={"sideMenu"} moduleId={0}
                            child={(props) => <ExotelIntegration {...props} />}
                        />} />
                        <Route path='/settings/channel-integration' element={<ChannelIntegration />} />
                        <Route path='/settings/manage-serviceable-pincodes' element={<PermissionWrapper module={"sideMenu"} moduleId={0}
                            child={(props) => <ManageServiceablePincode {...props} />}
                        />} />
                        <Route path='/settings/store-details' element={<StorageDetails />} />
                        <Route path='/settings/warehouse/address' element={<WareHouseAddress />} />
                        <Route path='/settings/invoice-template' element={<InvoiceTemplate />} />
                        <Route path='/settings/label-template' element={<LabelTemplate />} />
                        <Route path='/settings/invoice/hsn-gst' element={<HsnGst />} />
                        <Route path='/settings/billing' element={<Billing />} />
                        <Route path='/shopify-channel-integration' element={<SelfInstallation />} />
                        <Route path='/settings/manage-plan' element={<ManagePlan />} />
                        <Route path='/settings/manage-plan/plans' element={<BillingPlans />} />

                        <Route path='/products' element={<PermissionWrapper module={"sideMenu"} moduleId={18}
                            child={(props) => <Products {...props} />}
                        />} />
                        <Route path='/customers' element={<PermissionWrapper module={"sideMenu"} moduleId={17}
                            child={(props) => <Customers {...props} />}
                        />} />
                        <Route path='/products/product-details/:id' element={<PermissionWrapper module={"sideMenu"} moduleId={18}
                            child={(props) => <ProductDetails {...props} />}
                        />} />
                        <Route path='/customers/customer-details/:id' element={<PermissionWrapper module={"sideMenu"} moduleId={17}
                            child={(props) => <CustomerDetails {...props} />}
                        />} />


                        <Route path='/error-log' element={<PermissionWrapper module={"sideMenu"} moduleId={0}
                            child={(props) => <CourierErrorLogs {...props} />}
                        />} />
                      
                        <Route
                            path="*"
                            element={<PageNotFound />}
                        />
                    </Route>

                    {/* <Route path='/sidebar' element={<Sidebar />} /> */}
                    {/* <Route path='/orders-menu' element={<OrdersTabMenu/>} /> */}
                </Routes>
            </BrowserRouter>
        </>
    )
}
export default RootApp;
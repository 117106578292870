import React, { useState, useRef } from 'react';

const NotServiceableFilter = ({ notServiceablefilterOptions, onApply, onClear, checkList }) => {

    const [list, setList] = useState(checkList);
    const ref = useRef(null);
    const selectObj = (event) => {
               
        if (event.target.checked) {
            setList(prevList => [...prevList, event.target.value])
        } else {
            setList(prevList => prevList.filter(service => service !== event.target.value))
        }


    }

    const clearAll = () => {
        ref.current.reset();
        setList([]);      
        onClear();
    }
    return (
        <div className="accordion-wrapper">
            <ul className="dropdown-list">
                <div style={{ maxHeight: '180px', overflowY: 'auto', overflowX: 'hidden' }}>
                    <form ref={ref}>
                        {
                            notServiceablefilterOptions.map((option) => {
                                return <p className="menuLink" key={option.shipping_carrier_name}>
                                    <label className="form-check-label  checkboxLabel">
                                        <input type="checkbox" className="form-check-input"  checked={list.includes(option.shipping_carrier_name)} onChange={selectObj} value={option.shipping_carrier_name} />
                                        &nbsp;{option.shipping_carrier_name.charAt(0).toUpperCase() + option.shipping_carrier_name.slice(1)}</label>
                                </p>
                            })
                        }
                    </form>

                </div>
                <hr />
                <li className='d-flex space-between mb-2'>
                    <button type='button' className='btn btn-sm  clear-all-btn' onClick={clearAll}>Clear All</button>
                    <button type='button' className='btn btn-sm  apply-filter-btn' onClick={() => onApply(list)}>Apply Filter</button>
                </li>
            </ul>
        </div>
    )
}

export default NotServiceableFilter
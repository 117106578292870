import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { commonServiceMethod } from '../../../helpers/helper';
import Loader from '../loader';
import { STORE_ID } from '../../../constants';
import FeatureIcon from "../../../assets/FeaturedIcon.svg";

const CreateWareHouse = ({ item, show, handleClose, handleSave, handleGetData, courierData ,addOrEdit }) => {

    console.log(item, handleSave);
    const [loader, setLoader] = useState(false);
      //const [enableSaveBtn, setEnableSaveBtn] = useState(false);

    const [formData, setFormData] = useState({
        store_id: STORE_ID,
        shipping_carrier_name: "",
        name: '',
        address1: '',
        // contactPersonName: '',
        address2: '',
        // company: '',
        address3: '',
        email_id: '',
        mobile_no: '',
        origin_area: '',
        // gstno: '',
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        
      if (addOrEdit === "edit") {
        const updatedItem = { ...item };
        delete updatedItem.created_at;
        delete updatedItem.is_shopify_location;
        setFormData(updatedItem);
      }
     
    }, [item, addOrEdit]);
  
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      if (name === "is_default") {
        setFormData({ ...formData, [name]: e.target.checked ? "Y" : "N" });
      } else {
        setFormData({ ...formData, [name]: value });
      }
    };
  
    const handleCheckboxChange = () => {
      const values = Array.from(document.getElementsByName('shipping_carrier_name'))
        .filter(el => el.checked)
        .map(el => el.value);
      setFormData({ ...formData, shipping_carrier_name: values });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      let newErrors = validateFormData();
      if (Object.keys(newErrors).length === 0) {
        setErrors({});
        await saveWarehouseData();
      } else {
        setErrors(newErrors);
      }
    };
  
    const validateFormData = () => {
      const newErrors = {};
      if (addOrEdit !== "delete") {
        if (formData.shipping_carrier_name.length === 0) {
          newErrors.shipping_carrier_name = "Please select courier";
        }
        if (!formData.name) {
          newErrors.name = "Warehouse name is required";
        }
        if (!formData.address1) {
          newErrors.address1 = "Address Line 1 is required";
        }else if(formData.address1.length>60){
          newErrors.address1 = "60 Characters Only Allowed";
        }
        if (!formData.address3) {
          newErrors.address3 = "City is required";
        }
        if (!formData.email_id) {
          newErrors.email_id = "Email address is required";
        }
        if (!formData.mobile_no) {
          newErrors.mobile_no = "Phone is required";
        }
      }
      return newErrors;
    };
  
    const saveWarehouseData = async () => {
      const config = {};
      const data = { ...formData };
      let url = "";
      let method = "post";
  
      if (addOrEdit === "add") {
        url = `/app/location/add`;
      } else if (addOrEdit === "edit") {
        url = `/app/location/update`;
      } else if (addOrEdit === "delete") {
        url = `/app/location/delete?locationId=${item.id}`;
        method = "delete";
      }
  
      commonServiceMethod(url, method, data, config, setLoader, (res) => {
        if (res?.data?.status_code?.toString() === '200') {
          handleGetData();
          handleClose();
        }
      });
    };
  
    return (
      <>
        <Loader showHide={loader} />
        <Modal size='lg' show={show === 'createWarehouse'} onHide={handleClose} centered scrollable>
          <div className='modal-content modal-lg'>
            <div className="modal-header border-bottom-0 justify-content-between">
              <h5 className="modal-title">Add Warehouse</h5>
            </div>
            <div className="modal-body modal-lg">
              <form onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-12'>
                    <p className='setting-courier-model-label mb-0'>
                      Select Courier<span className="text-danger">*</span>
                    </p>
                    <div>
                      {courierData && courierData.map((courier) => (
                        <label key={courier.shipping_carrier_name} className="setting-courier-model-label courier-checkbox">
                          <input
                            type="checkbox"
                            className="shipping_carrier_name"
                            name="shipping_carrier_name"
                            value={courier.shipping_carrier_name}
                            checked={formData.shipping_carrier_name.includes(courier.shipping_carrier_name)}
                            onChange={handleCheckboxChange}
                          />{" "}
                          &nbsp;{courier.shipping_carrier_name.charAt(0).toUpperCase() + courier.shipping_carrier_name.slice(1)}
                        </label>
                      ))}
                    </div>
                    <div style={{paddingBottom:"10px"}}>
                    {errors.shipping_carrier_name && <p className="error">{errors.shipping_carrier_name}</p>}
                    </div>
                  </div>
                  {[
                    { label: "Warehouse Name", name: "name", required: true },
                    { label: "Address Line 1 (Max 60 Chars)", name: "address1", required: true },
                    { label: "Address Line 2", name: "address2" },
                    { label: "City", name: "address3", required: true },
                    { label: "Email Address", name: "email_id", required: true },
                    { label: "Pin code", name: "pincode", required: true, maxLength: 12 },
                    { label: "Phone Number", name: "mobile_no", required: true, maxLength: 10 },
                    { label: "State or Province", name: "origin_area" },
                    { label: "GST Number", name: "gstno" },
                  ].map((field) => (
                    <div key={field.name} className="col-md-6 mb-3">
                      <label className="setting-courier-model-label">
                        {field.label} {field.required && <span className="text-danger">*</span>}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name={field.name}
                        value={formData[field.name]}
                        onChange={handleChange}
                        maxLength={field.maxLength}
                      />
                      {errors[field.name] && <p className="error">{errors[field.name]}</p>}
                    </div>
                  ))}
  
                  <div className="col-md-6 mb-3">
                    <label className="setting-courier-model-label">
                      <input
                        type="checkbox"
                        name="is_default"
                        checked={formData.is_default === "Y"}
                        value={formData.is_default === "Y"}
                        onChange={handleChange}
                      />
                      &nbsp; Set as Default Address
                    </label>
                  </div>
                </div>
  
                <div className="d-flex justify-content-end">
                  <button type="button" onClick={handleClose} className="btn btn-sm btn-light">
                    Cancel
                  </button>
                  &nbsp;
                  <button type="submit" className="btn btn-sm btn-primary">
                    {addOrEdit === "add" ? "Save" : "Update"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>
        <Modal
            size='md'
            show={show === 'deleteLocation'}
            onHide={handleClose}
            centered
            scrollable
            className='courier-delete-popup'
            backdrop="static"
        >
            {/* {cType === 'delhivery' ? */}
                <div className='modal-content modal-lg'>
                    <div className="modal-body modal-lg">
                        <div className='row'>
                            <div className='col-md-2' style={{ paddingRight: '0px' }}>
                                <img src={FeatureIcon} />
                            </div>
                            <div className='col-md-8' style={{ paddingLeft: '0px' }}>
                                <h5 style={{ color: '#101828', fontSize: '18px', fontWeight: '600' }}>Delete</h5>
                                <p style={{ color: '#667085', fontSize: '14px' }}>Are you sure want to Delete? </p>
                            </div>
                        </div>
                    </div>

                </div>
                {/* : ''} */}

            <div className="modal-footer">
                
                <div className="col-12 mb-4 d-flex justify-content-end">
                    <button className="btn btn-sm cancel-btn" type="button" onClick={handleClose}>Cancel</button> &nbsp;&nbsp;
                    <button className="btn btn-sm clear-now-btn btn-danger" onClick={handleSubmit} type="submit" >Delete Now</button>

                </div>

            </div>

        </Modal>


    </>)

}

export default CreateWareHouse;
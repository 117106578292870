import React, {
    useState,
    useEffect,
    useRef,
    // useCallback
} from 'react';
import './style.scss';
import { useSelector } from 'react-redux';
import instance from '../../../helpers/axiosInstance';
import { Link } from 'react-router-dom';
import Search from '../../../assets/Iconsearch.svg';
import Calandar from '../../../assets/Icondate.svg';
import ScanIcon from '../../../assets/scan.png';
import AllFilter from '../../../assets/Iconall-filter.svg';
import copy from '../../../assets/copy.png';
// import { Popup } from "../../global/popupModal/BottomDialog";
import DataGridPagination from '../../global/dataGridPagination';
import Moment from 'react-moment';
import moment from 'moment';
// import shopify from '../../../assets/shopify.png'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
// import redPhone from '../../../assets/red-phone.svg';
// import greenPhone from '../../../assets/green-phone.svg';
// import blackPhone from '../../../assets/black-phone.svg';
// import yellowPhone from '../../../assets/yellow-phone.svg';
import actionButton from '../../../assets/action-button.png';
import DateRangePicker from 'react-bootstrap-daterangepicker';
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
// import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
import FilterMenu from "../../global/filterComponent/FilterMenu";
import CallStatus from '../../global/popupModal/CallStatus';
import { filterOptions } from './filterObj';
import { Slide, toast } from "react-toastify";
import SuccessToast from '../../customToast/SuccessToast';
import ErrorToast from '../../customToast/ErrorToast';
// import refreshIcon from '../../../assets/dashboard/refreshIcon.png';
import Loader from '../../global/loader';
import { commonServiceMethod } from '../../../helpers/helper';
import { Modal } from 'react-bootstrap';
//import ScanBtn from '../../../assets/scanBtn.svg'
// import { async } from 'q';
import CloseIcon from '../../../assets/close -Icon.png';

const ReadyToShipBody = () => {
    const userClaim = useSelector((state) => state.user);
    const gridRef = useRef();
    const [rowData, setRowData] = useState();
    const [originalData, setOriginalData] = useState({});
    const [selectedObject, setSelectedObject] = useState([]);
    const [open, setOpen] = useState(false);

    // pagination and Filtr variables 
    const per_page = 50;
    const [pageNo, setPageNo] = useState(1);
    // moment().subtract(30, 'days').toDate(), moment().toDate()
    const [startDate, setStartDate] = useState(moment().subtract(30, 'days').toDate())
    const [endDate, setEndDate] = useState(moment().toDate());
    const [searchText, setSearchText] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const [loader, setLoader] = useState(false);
    // const [callRowID, setCallRowID] = useState('');
    const [popupData, setPopupData] = useState({ popupName: "", data: "", callBackMethod: null });
    const [barCodeScanPopupOpen, setBarCodeScanPopupOpen] = useState(false);
    const [barCodeScanValues, setBarCodeScanValues] = useState({
        currentVal: '',
        values: [],
        validValues: [],
        inValidValues: [],
        isValid: true
    });


    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        console.log(isOpen);
    };


    const columnDefs = [
        {
            headerName: 'Order #',
            field: 'order_name',
            headerCheckboxSelection: true,
            checkboxSelection: true,
            wrapText: true,
            autoHeight: true,
            width: 200,
            cellClass: "check-box-container order-number",
            cellRenderer: (param) => {
                let productNames = param.data.orderProducts;
                return (<div>
                    <Link className='grid-link' to={`/orders/ready-to-ship/order-details/${btoa(param.data?.order_id)}`}>{param.value}</Link>
                    <p className='font-insetMedium order-date'><Moment format={"DD MMM YYYY hh:mm A"}>{param.data?.order_created_at}</Moment> </p>
                    {/* <p className='logo'><img alt='' src={shopify} /> */}
                        {/* {param.data?.tag && <span className="order-tag-status">{param.data?.tag}</span>} */}
                    {/* </p> */}
                    <OverlayTrigger rootClose={true} trigger="click" placement="bottom" overlay={
                        <Popover id="popover-basic" className='z-index-2000 produc-list'>
                            {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
                            <Popover.Body>
                                {productNames.map((i, j) => <div key={j} className='product-list-container'>
                                    <p className='p-name bold'><b>{i.product_name}</b></p>
                                    <p className='p-sku'>SKU <b>{i.sku}</b></p>
                                    <p className='p-qty'>QTY <b>{i.quantity}</b></p>
                                </div>)}
                            </Popover.Body>
                        </Popover>
                    }>
                        <p className='viewMore'>
                            View Products
                        </p>
                    </OverlayTrigger>
                </div>)
            }
        },
        {
            width: 220,
            field: 'shipping_address',
            wrapText: true,
            autoHeight: true,
            headerName: 'Customer',
            cellClass: "d-flex-center customers",
            cellRenderer(params) {
                let address = params.data?.shipping_address;
                return (
                    <div className="address-wrap">
                        <p className="customer-name">
                            {params.data?.customer_name}
                        </p>
                        <p className="address">
                            {params.data?.customer_email}
                        </p>
                        <p className="address">
                            {address.phone ? address.phone : ''}
                        </p>
                        <p className="address">
                            {address.city ? address.city : ''} - {address.zip ? address.zip : ''}
                        </p>
                    </div>
                )
            }
        },
        {
            headerName: 'Total',
            field: 'total_price',
            width: 120,
            cellClass: "d-flex-center",
            cellRenderer(params) {
                let payment_mode = "";
                let payment_status = "";
                if (params.data?.gateway === "Cash on Delivery (COD)") {
                    payment_mode = "COD";
                    payment_status = "info";
                } else {
                    payment_mode = "Pre-paid";
                    payment_status = "success";
                }
                return (
                    <div className='address-wrap'>
                        <p className="price_tag">{"Rs. " + params.value}</p>
                        <p className="price_tag">
                            <span className={payment_status}>{payment_mode}</span>
                        </p>
                    </div>
                )
            }
        },
        {
            field: 'manageShipments',
            width: 140,
            wrapText: true,
            autoHeight: true,
            headerName: 'Carrier',
            cellClass: "carrier-data",
            cellRenderer(param) {
                return (<>
                    {param.value.length === 0 || (param.value[0].tracking_number === null || param.value[0].tracking_number?.trim() === "") ? "-" : <>
                        <p className='carrier'>{param.value[0].ecom_name}</p><span className={`delivery-id`}>#{param.value[0].tracking_number || ""} <img onClick={() => {
                            navigator.clipboard.writeText(param.value[0].tracking_number);
                            toast(<SuccessToast body={'Coppied'} />, {
                                transition: Slide,
                                hideProgressBar: true,
                                autoClose: 2000,
                            });
                        }}
                            alt='' src={copy} /></span>
                    </>}
                </>)
            }
        },
        {
            field: 'callStatus',
            width: 120,
            wrapText: true,
            autoHeight: true,
            headerName: 'Fulfil Status',
            cellClass: 'order-fulfillment-status',
            cellRenderer(param) {
                const { print_count, invoice_count } = param.data;
                return (<>
                    <div>
                        <span className={`status-value fulfilled`}>Label: {print_count}</span>
                    </div>
                    <div>
                        <span className={`status-value fulfilled`}>Invoice: {invoice_count}</span>
                    </div>
                </>
                )
            }
        },
        {
            width: 120,
            field: 'order_fulfillment_status',
            wrapText: true,
            autoHeight: true,
            headerName: 'Print Status',
            cellClass: 'order-fulfillment-status',
            cellRenderer(param) {
                return (<span className={`status-value ${param.value}`}>{param.value}</span>)
            }
        },
        {
            field: 'app_order_status',
            width: 150,
            wrapText: true,
            autoHeight: true,
            headerName: 'Status',
            cellClass: "app-order-status",
            cellRenderer: (params) => {
                let order_status_class = "";
                if (params.value.toLowerCase() === "cancel") {
                    order_status_class = "cancel";
                } else if (params.value.toLowerCase() === "refunded") {
                    order_status_class = "refund";
                } else if (params.value.toLowerCase() === "delivered") {
                    order_status_class = "delivered";
                } else {
                    order_status_class = "";
                }
                return (<span className={`default-status ${order_status_class}`}>
                    {params.value}
                </span>)
            }
        },
        {
            width: 100,
            field: '',
            wrapText: true,
            autoHeight: true,
            headerName: 'Actions',
            cellRenderer(param) {
                return (
                    <div className="more-action">
                        <OverlayTrigger className="action-button" rootClose={true} trigger="click" placement="bottom" overlay={
                            <Popover id="popover-basic" className='z-index-2000 action-buttons w-20'>
                                <Popover.Body>
                                    <button className='print-invoice' onClick={() => printInvoiceMethod([param?.data])}>Print Invoice</button>
                                    <button className='print-invoice' onClick={() => printLabelMethod([param?.data])}>Print Label</button>
                                    <button className='print-invoice' onClick={() => createManifestMethod([param?.data])}>Create Manifest</button>
                                </Popover.Body>
                            </Popover>
                        }>
                            <img alt='Action' src={actionButton} />
                        </OverlayTrigger>

                    </div>
                )
            }
        },
    ];

    useEffect(() => {
        if (userClaim.token !== "") {
            getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), searchText);
        }
    }, [userClaim]);

    const onClearFilter = () => {
        getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), "");
    }

    const closeModal = () => {
        // setCallDetails('');
        setPopupData({ popupName: "", data: "", callBackMethod: null });
    }

    const getData = async (
        page = 1,
        startDate,
        endDate,
        searchText,
        perams = "&payment_type=&fulfillment=&order_status=&carrier=&tag="
    ) => {
        var rowData = [];
        if (searchText !== "") {
            if (/^\d{10}$/.test(searchText)) {
                searchText += '&orderDetails=m'
                searchText = searchText.replace("#", "%23");
            }
            else if (searchText.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )) {
                searchText += '&orderDetails=e'
                
                searchText = searchText.replace("#", "%23");
            }
            else {
                searchText += '&orderDetails='
                searchText = searchText.replace("#", "%23");
            }
        }
        setLoader(true);
        try {
            await instance.get(`app/order/getOrder?per_page=${per_page}&page=${page}&order_date=${startDate}&end_order_date=${endDate}&type=Ready to Ship&search=${searchText}${perams}`).then((res) => {
                if (res.status === 200 && Array.isArray(res?.data?.orders)) {
                    setRowData(res?.data?.orders || []);
                    setOriginalData(res?.data)
                }
                setLoader(false);
            }).catch(e => {
                setLoader(false);
                console.error(e);
                setRowData([]);
            })
        }
        catch (e) {
            setLoader(false);
            console.error(e);
            setRowData([]);
        }
        return rowData;
    }

    const handlePageClick = (event) => {
        setPageNo(event.selected + 1);
        getData(
            event.selected + 1,
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
            searchText
        );
    }

    const onCellClick = (e) => {
        let selectedITem = e.api.getSelectedRows();
        console.log(selectedITem.length > 0)
        // setSelectedObject((selectedObject) => [...selectedObject]);
        selectedITem.length > 0 ? (setOpen(true), setSelectedObject([...selectedITem])) : (setOpen(false), setSelectedObject([]));
    }
    const dateChangeEvent = (date) => {
        setStartDate(date.startDate.format('YYYY-MM-DD'));
        setEndDate(date.endDate.format('YYYY-MM-DD'));
        getData(
            pageNo,
            date.startDate.format('YYYY-MM-DD'),
            date.endDate.format('YYYY-MM-DD'),
            searchText
        )
    }
    const searchTextChangeEvent = (e) => {
        e.preventDefault();
        getData(
            pageNo,
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
            searchText
        );
    }

    const onApplyFilter = (selectionOption) => {
        console.log(selectionOption)
        let url = "";
        Object.keys(selectionOption).map(i => {

            url += `&${i}=${selectionOption[i]}`
        })
        getData(
            pageNo,
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
            searchText,
            url
        );
        toggleDropdown();
    }
    const closeExportPopup = () => {
        setOpen(false);
        setSelectedObject([])
        gridRef.current.api.deselectAll();
    }
    const unPrintMethod = (e) => {
        getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), searchText, `&sort_by=print_count&sort_type=${e.target.checked ? 'asc' : 'desc'}`);
    }
    const printInvoiceMethod = (obj = []) => {
        obj = typeof obj.length === 'undefined' ? selectedObject : obj;
        const config = {
            headers: { Accept: "application/pdf" },
            responseType: "blob",
        };
        var objdata = "";
        objdata = obj.map((i) => i.order_id).join();
        commonServiceMethod(`app/order/print-invoice/${objdata}?templateName=temp1`, "get", {}, config, setLoader, (res) => {
            const blob = new Blob([res.data], { type: "application/pdf" });
            const objectUrl = window.URL.createObjectURL(blob);
            window.open(objectUrl);
            closeExportPopup();
        });
    }

    const printLabelMethod = (obj = []) => {
        obj = typeof obj.length === 'undefined' ? selectedObject : obj;
        const config = {
            headers: { Accept: "application/pdf" },
            responseType: "blob",
        };
        var objdata = {};
        objdata.order_id = obj.map((i) => i.order_id).join();
        commonServiceMethod("app/pritnlabel", "post", objdata, config, setLoader, (res) => {
            const blob = new Blob([res.data], { type: "application/pdf" });
            const objectUrl = window.URL.createObjectURL(blob);
            window.open(objectUrl);
            closeExportPopup();
        });
    }

    const createManifestMethod = (obj = []) => {
        obj = typeof obj.length === 'undefined' ? selectedObject : obj;
        const config = {};
        var objdata = {};
        objdata.order_id = obj.map((i) => i.order_id).join();
        commonServiceMethod("app/create/manifest", "post", objdata, config, setLoader, (res) => {
            if (res.data.status_code === 200) {
                toast(<SuccessToast body={'Manifest created Sucessfully'} />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 2000,
                });
                closeExportPopup();
                getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), searchText);
            }
        });
    }

    const onSubmitBarCodeHandler = () => {
        const config = {};
        const orderId = barCodeScanValues.values;
        if (orderId.length > 0) {
            commonServiceMethod("app/create/manifestByAWBNumber", "post", { awbNumber: orderId.join(",") }, config, setLoader, (res) => {
                if (res.data.status_code === 200) {
                    toast(<SuccessToast body={'Scanned code validated successfully'} />, {
                        transition: Slide,
                        hideProgressBar: true,
                        autoClose: 2000,
                    });
                    const barCodeVal = {
                        ...barCodeScanValues
                    }
                    if (res.data?.successcount > 0) {
                        barCodeVal.validValues = res.data?.successmessage;
                        barCodeVal.inValidValues = barCodeVal.values.filter((item) => !res.data?.successmessage.includes(item));
                    }
                    if (res.data?.successcount === 0) {
                        barCodeVal.inValidValues = [...barCodeVal.values];
                    };
                    barCodeVal.values = []
                    setBarCodeScanValues(barCodeVal);
                    getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), searchText);
                }
                if (res.data.status_code === 500) {
                    toast(<ErrorToast body={res.data?.message} />, {
                        transition: Slide,
                        hideProgressBar: true,
                        autoClose: 2000,
                    });
                }
            });
        }
    }

    const handleClose = () => {
        setBarCodeScanPopupOpen(false);
    }

    const onScanBarCodeHandler = () => {
        setBarCodeScanPopupOpen(true);
    }

    const onInputChangeHandler = (e) => {
        const barCodeVal = {
            ...barCodeScanValues
        }
        barCodeVal.currentVal = e.target.value;
        barCodeVal.isValid = true;

        setBarCodeScanValues(barCodeVal);
    }

    const onSubmitScanHandler = (e) => {
        if (e.key === 'Enter') {
            const barCodeVal = {
                ...barCodeScanValues
            }
            if (barCodeVal.values.length >= 50) return;
            if (barCodeVal.inValidValues.length > 0 || barCodeVal.validValues.length > 0) {
                barCodeVal.inValidValues = [];
                barCodeVal.validValues = [];
            }
            const isValid = barCodeVal.values?.indexOf(e.target.value) === -1;
            if (isValid) barCodeVal.currentVal = '';
            if (isValid) barCodeVal.values = [...barCodeVal.values, e.target.value]
            barCodeVal.isValid = isValid;

            setBarCodeScanValues(barCodeVal);
        }
    }

    return (
        <>
            <Loader showHide={loader} />
            <div className='quick-buttons'>
                {/* <button className='quickBtn' onClick={() => showModal('quickAccess', 'quickAccess', quickAccessMethod)}> <img src={gridIcon} className="gridIcon"></img> Quick Actions</button> */}
                {/* <button className='syncBtn'> <img src={refreshIcon} className="refreshIcon"></img> Sync Orders</button> */}
                {/* <button className='syncBtn' onClick={() => showModal('courierPartner', 'courierPartner')}>Assign Courier Partner</button>
                <button className='syncBtn' onClick={() => showModal('scanawb', 'scanawb')}>Scan/Paste AWBs</button> */}
            </div>
            {/* {open ? <Popup exportMethod={exportMethod} printInvoiceMethod={printInvoiceMethod} selecteObject={selectedObject} closePopup={() => { closeExportPopup(); }} /> : null} */}
            {open ? 
            <div className={`popup-container grid-checkbox-popuop`}>
                <div className="popup-body">
                    <div className="left-container">
                        <span className="selected-item"> {selectedObject.length} items Seleted</span>
                    </div>
                    <div className="right-container">
                        <button type='button' onClick={printLabelMethod} className='btn btn-sm  asign-courier filter-btn'>Print Label</button>
                        <button type='button' onClick={printInvoiceMethod} className='btn btn-sm filter-btn'>Print Invoice</button>
                        <button type='button' onClick={createManifestMethod} className='btn btn-sm filter-btn'>Create Manifest</button>
                        <Link to={''} className='close-icon' onClick={closeExportPopup} > <img src={CloseIcon}/>  </Link>
                    </div>
                </div>
            </div>
            // <div className="ready-to-ship popup-container fixed">
            //     <div className="popup-body">
            //         <div className='row'>
            //             <div className='col-md-3'>
            //                 {selectedObject?.length} items Seleted
            //             </div>
            //             <div className='col-md-9'>
            //                 <div className="row">
            //                     <div className="col-md-3">
            //                         <button type='button' onClick={printLabelMethod} className='btn btn-sm w-80 filter-btn'>Print Label</button>
            //                     </div>
            //                     <div className="col-md-4">
            //                         <button type='button' onClick={printInvoiceMethod} className='btn btn-sm w-100 filter-btn'>Print Invoice</button>
            //                     </div>
            //                     <div className="col-md-4">
            //                         <button type='button' onClick={createManifestMethod} className='btn btn-sm w-80 filter-btn'>Create Manifest</button>
            //                     </div>
            //                     <div className="col-md-1">
            //                         <Link to={''} onClick={closeExportPopup} >X</Link>
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            // </div>
             : null}
            <div className='row mb-2 mt-4 filter-section ready-to-shipment'>
                <div className="filter-left-container">
                    <div className="form-group">
                        <form onSubmit={searchTextChangeEvent}>
                            <div style={{ position: 'relative' }}>
                                <span className="icon"><img alt='' src={Search} /></span>
                            </div>
                            <input
                                type="text"
                                id="filter-text-box"
                                placeholder="Search"
                                className='form-control inputWithIcon'
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                        </form>
                    </div>
                </div>
                <div className='filter-right-container'>
                    <div className='scan-btn'>
                    <OverlayTrigger rootClose={true} trigger="hover" placement="left" overlay={
                        <Popover id="popover-basic" className='z-index-2000 scan-tooltip'>
                            {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
                            <Popover.Body>
                            Scan/Paste AWB to Manifest
                            </Popover.Body>
                        </Popover>
                    }>
                        <button type="button" onClick={onScanBarCodeHandler} className="btn btn-sm w-100 filter-btn m-1 p-2" style={{ color: 'rgb(127, 86, 217)', textAlign: 'center' }}><img alt='' style={{ width: 15 }} src={ScanIcon} /></button>
                    </OverlayTrigger>                        
                    </div>
                    <div className='unprint'>
                        <button type='button' className='btn btn-sm filter-btn py-0' style={{ color: '#7F56D9' }}>
                            <div className="form-check my-0" style={{ margin: "0 auto" }}>
                                <input type="checkbox" className="form-check-input" id="validationFormCheck1" onChange={unPrintMethod} />
                                <label className="form-check-label unprint-lable checkboxLabel" htmlFor="validationFormCheck1">Unprinted First</label>
                            </div>
                        </button>
                    </div>

                    <div className="form-group datepicker-class">
                        <div style={{ position: 'relative' }}>
                            <span className="icon"><img alt='' src={Calandar} /></span>
                        </div>
                        <DateRangePicker
                            className="datepicker-custom"
                            initialSettings={
                                {
                                    startDate: startDate,
                                    endDate: endDate,
                                    maxDate: moment().toDate(),
                                    showDropdowns: true,
                                    locale: {
                                        format: 'MMM DD, YYYY',
                                    },
                                    ranges: {
                                        Today: [moment().toDate(), moment().toDate()],
                                        Yesterday: [
                                            moment().subtract(1, 'days').toDate(),
                                            moment().subtract(1, 'days').toDate(),
                                        ],
                                        'Last 7 Days': [
                                            moment().subtract(6, 'days').toDate(),
                                            moment().toDate(),
                                        ],
                                        'Last 30 Days': [
                                            moment().subtract(30, 'days').toDate(),
                                            moment().toDate(),
                                        ],
                                        'This Month': [
                                            moment().startOf('month').toDate(),
                                            moment().endOf('month').toDate(),
                                        ],
                                        'Last Month': [
                                            moment().subtract(1, 'month').startOf('month').toDate(),
                                            moment().subtract(1, 'month').endOf('month').toDate(),
                                        ],
                                    },
                                }}
                            onApply={(e, val) => dateChangeEvent(val, e)}
                        >
                            <input type="text" className="form-control datepicker-range" />
                        </DateRangePicker>
                    </div>
                    <div className='dropdown-dialog-parent'>
                        <div className={` dropdown-dialog ${isOpen ? 'open' : ''}`}>
                            <button type='button' className='btn btn-sm  filter-btn' onClick={toggleDropdown} ><img alt='' style={{ width: 15 }} src={AllFilter} /> All Filters</button>
                            {isOpen && (
                                <>
                                    <FilterMenu filterOptions={filterOptions} onClear={onClearFilter} onApply={onApplyFilter}></FilterMenu>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                {/* <div className='col-md-4'>
                    <div className='col-md-6'>
                        <div className="form-group">
                            <form onSubmit={searchTextChangeEvent}>
                                <div style={{ position: 'relative' }}>
                                    <span className="icon"><img alt='' src={Search} /></span>
                                </div>
                                <input
                                    type="text"
                                    id="filter-text-box"
                                    placeholder="Search"
                                    className='form-control inputWithIcon'
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                            </form>
                        </div>
                    </div>
                </div> */}


                {/* <div className='col-md-1 mt-1'>
                    <button type='button' onClick={onScanBarCodeHandler} className='btn btn-sm w-100 filter-btn px-3 py-0 my-0' style={{ color: '#7F56D9' }}><img alt='' style={{ width: 15 }} src={ExportIcon} /></button>
                </div>
                <div className='col-md-2 mt-1'>
                    <button type='button' className='btn btn-sm w-100 filter-btn py-0' style={{ color: '#7F56D9' }}>
                        <div className="form-check my-0" style={{ margin: "0 auto", width: "110px" }}>
                            <input type="checkbox" className="form-check-input" id="validationFormCheck1" onChange={unPrintMethod} />
                            <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck1">Unprint First</label>
                        </div>
                    </button>
                </div> */}
                {/* <div className='col-md-3 mt-1'>
                    <div className="form-group">
                        <div style={{ position: 'relative' }}>
                            <span className="icon"><img alt='' src={Calandar} /></span>
                        </div>
                        <DateRangePicker
                            className="w-100"
                            initialSettings={
                                {
                                    startDate: startDate,
                                    endDate: endDate,
                                    maxDate: moment().toDate(),
                                    showDropdowns: true,
                                    locale: {
                                        format: 'MMM DD, YYYY',
                                    },
                                    ranges: {
                                        Today: [moment().toDate(), moment().toDate()],
                                        Yesterday: [
                                            moment().subtract(1, 'days').toDate(),
                                            moment().subtract(1, 'days').toDate(),
                                        ],
                                        'Last 7 Days': [
                                            moment().subtract(6, 'days').toDate(),
                                            moment().toDate(),
                                        ],
                                        'Last 30 Days': [
                                            moment().subtract(30, 'days').toDate(),
                                            moment().toDate(),
                                        ],
                                        'This Month': [
                                            moment().startOf('month').toDate(),
                                            moment().endOf('month').toDate(),
                                        ],
                                        'Last Month': [
                                            moment().subtract(1, 'month').startOf('month').toDate(),
                                            moment().subtract(1, 'month').endOf('month').toDate(),
                                        ],
                                    },
                                }}
                            onApply={(e, val) => dateChangeEvent(val, e)}
                        >
                            <input type="text" className="form-control datepicker-range w-100" />
                        </DateRangePicker>
                    </div>
                </div>
                <div className='col-md-2 mt-1'>
                    <div className={`w-100 dropdown-dialog ${isOpen ? 'open' : ''}`}>
                        <button type='button' className='btn btn-sm w-100 filter-btn' onClick={toggleDropdown} ><img alt='' style={{ width: 15 }} src={AllFilter} /> All Filters</button>
                        {isOpen && (
                            <>
                                <FilterMenu filterOptions={filterOptions} onClear={onClearFilter} onApply={onApplyFilter}></FilterMenu>
                            </>
                        )}
                    </div>
                </div> */}
                {/* <div className='col-md-6 mt-1 d-flex justify-content-between'>
                </div> */}
            </div >
            <DataGridPagination
                rowData={rowData}
                columnDefs={columnDefs}
                onSelectionChanged={onCellClick}
                gridref={gridRef}
                rowSelection={"multiple"}
                domLayout="autoHeight"
                onPageChange={handlePageClick}
                pageRangeDisplayed={originalData.per_page}
                pageCount={originalData.count}
            />
            {popupData.popupName !== "" &&
                <CallStatus
                    item={popupData.data}
                    show={popupData.popupName}
                    handleSave={popupData.callBackMethod}
                    handleClose={closeModal}
                />
            }
            <Modal
                size='md'
                show={barCodeScanPopupOpen}
                onHide={handleClose}
                centered
                scrollable
                className='barcode-scan-modal'
            >
                <div className='modal-content modal-lg'>
                    {/* <div className="modal-header border-bottom-0 justify-content-between">
                        
                    </div> */}
                    <div className="modal-body modal-lg">
                        <div>
                            <h5 className="modal-title">Scan/Paste AWBs to Manifest</h5>
                            <label className='userLabel'>Start scanning yours orders or paste your selected AWB:</label>
                            <input type='number' placeholder='Scan/Paste AWBs' className={`check-number form-control max-length ${barCodeScanValues.isValid ? 'valid' : 'invalid'}`} value={barCodeScanValues.currentVal} onChange={onInputChangeHandler} onPaste={onInputChangeHandler} onKeyPress={onSubmitScanHandler} />
                            {/* <span className='userLabel mb-3'>Max 4 characters</span> */}
                        </div>
                        {
                            (barCodeScanValues.validValues.length > 0 || barCodeScanValues.inValidValues.length > 0) && (
                                <div className='mt-3 d-flex justify-content-between w-50 m-auto'>
                                    <button type="button" className="btn btn-sm btn-success">
                                        Success: {barCodeScanValues.validValues.length}
                                    </button>
                                    <button type="button" className="btn btn-sm btn-danger">
                                        Failed: {barCodeScanValues.inValidValues.length}
                                    </button>
                                </div>
                            )
                        }

                       
                        {
                            barCodeScanValues.values.length > 0 && (
                                <div className='text-center mt-3'>
                                    <button type="button" className="btn btn-sm btn-success">
                                        Scanned: {barCodeScanValues.values.length}
                                    </button>
                                </div>
                            )
                        }
                        {
                            barCodeScanValues.inValidValues.length > 0 && (
                                <div className='text-center mt-3'>
                                    <button type="button" className="btn btn-sm btn-danger">
                                        Below Orders are failed to Manifest:
                                        <p className='bg-white text-danger error-msg'>{barCodeScanValues.inValidValues.join(", ")}</p>

                                    </button>
                                </div>
                            )
                        }
                    </div>

                </div>

                <div className="modal-footer justify-content-center">
                    <div className="col-12 mb-4 d-flex">
                        <button className="btn btn-sm cancel-btn" type="button" onClick={handleClose}>Cancel</button> &nbsp;&nbsp;
                        <button className="btn btn-sm btn-primary w-100" onClick={() => onSubmitBarCodeHandler()} type="submit">Create Manifest</button>
                    </div>

                </div>

            </Modal>
        </>
    );
}

export default ReadyToShipBody;


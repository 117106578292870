import React, { 
    useState
 } from 'react';
import ContentData from './contents'
import instance from '../../../helpers/axiosInstance';
import Loader from '../../global/loader';
import SuccessToast from '../../customToast/SuccessToast';
import { Slide, toast } from 'react-toastify';

function DivBox({ data,onRender}) {
    const [loader, setLoader] = useState(false);
    // const [isSwitchOn, setIsSwitchOn] = useState(parseInt(data.status) === 1);
    
    
    const toggleSwitch = async (item) => {
        // console.log(item)
        setLoader(true);
        await instance.get(`app/shippingCarriers/enableDisable?id=${item.id}&enable=${!item.enable}`)
            .then((res) => {
                // console.log(res)
                if(res?.data?.statusCode === 200){
                    data.enable = !item.enable ? 1 : 0;
                    toast(<SuccessToast body={`${data.shipping_carrier_name} updated successfully`} />, {
                        transition: Slide,
                        hideProgressBar: true,
                        autoClose: 2000,
                      });
                }
                setLoader(false);
            })
            .then((res) => {
                console.log(res)
                setLoader(false);
            })
    };
    const titleCase = (word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
    return (
        <>
            {loader && <Loader showHide={loader} />}
            <div className='card' style={{ borderRadius: '8px' }}>
                <div className='card-body' style={{ minHeight: '100%' }}>
                    <div className='row'>
                        <div className='col-md-11 d-flex'>
                            <h5 className='courier-card-head'>{titleCase(data.shipping_carrier_name)}</h5>
                        </div>

                        <div className='col-md-1 no-padding'>
                            <label className="switch" >
                                {/* <input type="checkbox" onChange={toggleSwitch} checked={isSwitchOn} /> */}
                                <input type="checkbox" onChange={()=>toggleSwitch(data)} checked={data?.enable} />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>
                    {data?.enable ? (
                        <ContentData datasContent={data} onRender={onRender} />
                    ) : ""}
                </div>
            </div>
        </>

    );
}

export default DivBox;

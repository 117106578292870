import React from 'react';
import PageLayout from '../../global/pageLayout';
import CourierErrorLogsBody from './body';

const CourierErrorLogs = () => {
    return (
        <div>
            <PageLayout tabMenu={false} body={<CourierErrorLogsBody />} />
        </div>
    )
}

export default CourierErrorLogs;
import React, {
    useState,
    useEffect,
    useRef
} from 'react';
import { useSelector } from 'react-redux';
import instance from '../../../helpers/axiosInstance';
import { Link } from 'react-router-dom';
import Search from '../../../assets/Iconsearch.svg';

import DataGridPagination from '../../global/dataGridPagination';
import moment from 'moment';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Popover from 'react-bootstrap/Popover';
// import actionButton from '../../../assets/action-button.png';

import './errorLog.scss';
import {
    checkAccess,
    //commonServiceMethod,
    //  commonServiceMethod 
} from '../../../helpers/helper';
import Loader from '../../global/loader';
import backArrow from '../../../assets/backArrow.svg';
import Moment from 'react-moment';
// import { Badge } from 'reactstrap';
// import Moment from 'react-moment';

const CourierErrorLogsBody = () => {
    const userClaim = useSelector((state) => state.user);
    const gridRef = useRef();
    const [rowData, setRowData] = useState();
    const [originalData, setOriginalData] = useState({});
    const per_page = 50;
    const [pageNo, setPageNo] = useState(1);
    const [startDate,
        // setStartDate
    ] = useState(moment().subtract(30, 'days').toDate())
    const [endDate,
        // setEndDate
    ] = useState(moment().toDate());
    const [searchText, setSearchText] = useState("");

    // const [, setIsOpen] = useState(false);

    const [loader,
        //  setLoader
    ] = useState(false);
    const [columnData, setComlumnData] = useState([]);
    
    const columnDefs = [
        {
            headerName: 'Order Id',
            field: 'order_name',
            wrapText: true,
            autoHeight: true,
            width: 120,
            cellClass: "check-box-container order-number",
            cellRenderer: (param) => {
                return (<div>
                    <Link className='grid-link' to={`/orders/new-order/order-details/${btoa(param.data?.order_id)}`}>{param.value}</Link>


                </div>)
            }
        },
        {
            headerName: 'Log Time',
            field: 'updated_at',
            wrapText: true,
            autoHeight: true,
            width: 180,
            cellClass: "check-box-container order-number",
            cellRenderer: (param) => {

                return (<div>
                    <p><Moment format={"DD/MM/YYYY hh:mm A"}>{param.data.updated_at}</Moment> </p>

                </div>)
            }
        },
        {
            headerName: 'Error Message',
            field: 'error_log',
            wrapText: true,
            autoHeight: true,
            width: 650,
            cellClass: "custom-line-height-wrapper",
            cellRenderer: (param) => {
                // let tags = param.data?.tag ? param.data?.tag.split(",") : false;               
                return (<div className='custom-line-height-wrapper' >
                   
                     <span ><i className="bi bi-info-circle" style={{ color: 'red', marginLeft: 5, marginRight: 5,lineHeight:1.2 }}></i></span>   <p >{param.data.error_log
                        }</p>
                  
                </div>)
            }
        },
        {
            headerName: 'Courier Source',
            field: 'order_name',
            wrapText: true,
            autoHeight: true,
            width: 150,
            cellClass: "check-box-container order-number",
            cellRenderer: (param) => {

                return (<div>
                    {param.data.carrier_service_name
                    }

                </div>)
            }
        },

    ];

    useEffect(() => {
        if (userClaim.token !== "") {
            getData(pageNo,searchText);
            if (!checkAccess(11)) {
                // columnDefs.splice(5, 1);
                setComlumnData([...columnDefs]);
            }
            else
                setComlumnData([...columnDefs]);
        }

    }, [userClaim]);


    const getData = ( searchText
    ) => {
        let page = 1;
        var rowData = [];
        let url = `app/shipping-services/geterror-logs?per_page=${per_page}&page=${page}`;
        try {
            if (searchText !== "") {
                url = `app/shipping-services/geterror-logs?per_page=${per_page}&page=${page}&search=${encodeURIComponent(searchText)}`;
                console.log("url::::::::::",url);
                if (searchText === 1) {
                    url = `app/shipping-services/geterror-logs?per_page=${per_page}&page=${page}`;
                }
            }

            instance.get(url).then((res) => {

                // console.log(res)

                if (res.status === 200 && Array.isArray(res?.data?.error_logs)) {
                    const extractedData = res?.data?.error_logs;
                    console.log("extractedData", extractedData);
                    setRowData(extractedData || []);
                    setOriginalData(res?.data)
                }

            }).catch(e => {
                console.error(e);
                setRowData([]);
            })
        }
        catch (e) {
            console.error(e);
            setRowData([]);
        }
        return rowData;
    }

    const handlePageClick = (event) => {
        setPageNo(event.selected + 1);
        getData(
            event.selected + 1,
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
            searchText
        );
    }

    const onCellClick = (e) => {
        let selectedITem = e.api.getSelectedRows();
        console.log(selectedITem.length > 0)
        // setSelectedObject((selectedObject) => [...selectedObject]);
        selectedITem.length > 0 ? (setOpen(true), setSelectedObject([...selectedITem])) : (setOpen(false), setIdCount([]));
    }
    // const dateChangeEvent = (date) => {
    //     setStartDate(date.startDate.format('YYYY-MM-DD'));
    //     setEndDate(date.endDate.format('YYYY-MM-DD'));
    //     getData(
    //         pageNo,
    //         date.startDate.format('YYYY-MM-DD'),
    //         date.endDate.format('YYYY-MM-DD'),
    //         searchText
    //     )
    // }
    const searchTextChangeEvent = (e) => {       
        e.preventDefault();
        getData(searchText)
        // getData(
        //     // pageNo,
        //     // moment(startDate).format('YYYY-MM-DD'),
        //     // moment(endDate).format('YYYY-MM-DD'),
        //     searchText
        // );
    }

    return (
        <>
            <Loader showHide={loader} />
            <div className="parent-div product-table">
                <div className='col-md-9'>
                    <Link to={'/orders/new'}>
                        <img src={backArrow} /> Back
                    </Link>

                </div>
                <div className="topview">

                    <p className='page-header-text mb-3'>Error Logs</p>
                </div>


                <div className='row mb-2 mt-4 filter-section'>
                    <div className="filter-left-container">
                        <div className="form-group">
                            <form onSubmit={searchTextChangeEvent}>
                                <div style={{ position: 'relative' }}>
                                    <span className="icon"><img alt='' src={Search} /></span>
                                </div>
                                <input
                                    type="text"
                                    id="filter-text-box"
                                    placeholder="Search"
                                    className='form-control inputWithIcon'
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                            </form>
                        </div>
                    </div>

                </div >
                <div className='product-table'>
                    <DataGridPagination
                        rowData={rowData}
                        columnDefs={columnData}
                        onSelectionChanged={onCellClick}
                        gridref={gridRef}
                        rowSelection={"multiple"}
                        domLayout="autoHeight"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={originalData.per_page}
                        pageCount={15}
                    />
                </div>

            </div>
        </>
    );
}

export default CourierErrorLogsBody;
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CourierPopup from '../../global/popupModal/courierModal';
import { commonServiceMethod } from '../../../helpers/helper';
import Loader from '../../global/loader';
import SuccessToast from '../../customToast/SuccessToast';
import { Slide, toast } from 'react-toastify';
import ErrorToast from '../../customToast/ErrorToast';

const Contents = ({ datasContent, onRender }) => {
  const [loader, setLoader] = useState(false);
  const [popupData, setPopupData] = useState({ popupName: "", courierType: "", data: "", callBackMethod: null });
  var settingData = {};

  if (datasContent?.shipping_carrier_name.toString()?.toLowerCase() === 'delhivery') {
    settingData = datasContent.delhiveryShippingCarriersRequest;
  }
  if (datasContent?.shipping_carrier_name.toString()?.toLowerCase() === 'bluedart') {
    settingData = datasContent.blueDartShippingCarriersRequest;
  }
  if (datasContent?.shipping_carrier_name.toString()?.toLowerCase() === 'dtdc') {
    settingData = datasContent.dtdcShippingCarriersRequest;
  }
  if (datasContent?.shipping_carrier_name.toString()?.toLowerCase() === 'ekart') {
    settingData = datasContent.ecartShippingCarriersRequest;
  }
  if (datasContent?.shipping_carrier_name.toString()?.toLowerCase() === 'pickrr') {
    settingData = datasContent.pickrrShippingCarriersRequest;
  }
  if (datasContent?.shipping_carrier_name.toString()?.toLowerCase() === 'xpressbees') {
    settingData = datasContent.xpressBeesShippingCarriersRequest;
  }
  if (datasContent?.shipping_carrier_name.toString()?.toLowerCase() === 'ecomexpress') {
    settingData = datasContent.ecomexpressShippingCarriersRequest;
  }
  if (datasContent?.shipping_carrier_name.toString()?.toLowerCase() === 'amazon') {
    settingData = datasContent.amazonShippingCarriersRequest;
  }

  //var settingData = JSON.parse(datasContent.setting_data);
  // if ('additional_data' in datasContent) {
  //   settingData = JSON.parse(datasContent.additional_data);
  // }
  // else {
  //   settingData = '';
  // }

  const setPopup = (funData, cType, id) => {
    setPopupData({ popupName: funData, courierType: cType, data: id, callBackMethod: save })
  }
  const closePopup = () => {
    setPopupData({ popupName: "", courierType: "", data: "", callBackMethod: null })
  }
  const save = async (obj) => {
   
    // delete obj.id
    await commonServiceMethod(`app/shipping_carriers/addshipping_carriers`, 'post', obj, {}, setLoader,
      (res) => {
        console.log(res)
        if (res?.data?.status_code === 200) {
       
          toast(<SuccessToast body={`${obj.shipping_carrier_name} updated successfully`} />, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 2000,
          });  
          onRender();    
          closePopup();

        }
        else {
          toast(<ErrorToast header='Error' body={`Please try again`} />, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 2000,
          });
          closePopup();
        }
      })

  }
  const check = datasContent?.shipping_carrier_name.toString()?.toLowerCase() === 'delhivery' ?

    <div className='content'>
      <div className='row' style={{ padding: '0px' }}>
        <div className='col-md-2' style={{ padding: '0px 10px 0 0' }}>
          <p className='title'>Account Name</p>
          <p className='data'>{datasContent?.display_name === '' ? datasContent?.shipping_carrier_name : datasContent.display_name}</p>
        </div>
        <div className='col-md-3' style={{ padding: '0px 10px 0 0' }}>
          {/* <p className='title'>Client/Warehouse name</p> */}
          {/* <p className='data'>{datasContent?.clientName}</p> */}
        </div>
        <div className='col-md-5' style={{ padding: '0px 10px 0 0' }}>
          <p className='title'> API Key</p>
          <p className='data'>{settingData?.key}</p>
        </div>
        <div className='col-md-2' style={{ padding: '0px 10px 0 0' }}>
          <p className='title d-flex justify-content-end'>Action</p>
          <p className='data d-flex justify-content-end'>
            <Link className='link' to={''} onClick={() => { setPopup('editCourier', 'delhivery', datasContent) }}>Edit</Link>&nbsp;&nbsp;
            <Link className='link' to={''} onClick={() => { setPopup('deleteCourier', 'delhivery', datasContent) }}><span style={{ color: 'red' }}>Delete</span></Link>
          </p>
        </div>
      </div>
    </div>

    : datasContent?.shipping_carrier_name.toString()?.toLowerCase() === 'bluedart' ?
      <div className='content'>
        <div className='row'>
          <div className='col-md-10'>
            <div className='row' style={{ padding: '0px' }}>
              <div className='col-md-4' style={{ padding: '0px 10px 0 0' }}>
                <p className='title'>Account Name</p>
                <p className='data'>{datasContent?.display_name === '' ? datasContent?.shipping_carrier_name : datasContent.display_name === '' ? datasContent?.shipping_carrier_name : datasContent.display_name === '' ? datasContent?.shipping_carrier_name : datasContent.display_name}</p>
              </div>
              <div className='col-md-4' style={{ padding: '0px 10px 0 0' }}>
                <p className='title'>Acount Number</p>
                <p className='data'>{settingData?.accountnumber}</p>
              </div>
              <div className='col-md-4' style={{ padding: '0px 10px 0 0' }}>
                <p className='title'>Customer Email</p>
                <p className='data'>{settingData?.customer_email_id}</p>
              </div>
              {/* <div className='col-md-4' style={{ padding: '0px 10px 0 0' }}>
                <p className='title'>{datasContent.display_name === '' ? datasContent?.shipping_carrier_name : datasContent.display_name} Tracking Key	loc</p>
                <p className='data'>{settingData?.traking_licence_key}</p>
              </div> */}

              <div className='col-md-4' style={{ padding: '0px 10px 0 0' }}>
                <p className='title'>Customer mobile</p>
                <p className='data'>{settingData?.customer_mobile}</p>
              </div>
              <div className='col-md-4' style={{ padding: '0px 10px 0 0' }}>
                <p className='title'>API Key</p>
                <p className='data'>{settingData?.key}</p>
              </div>
              <div className='col-md-4' style={{ padding: '0px 10px 0 0' }}>
                <p className='title'>Password</p>
                <p className='data'>{settingData?.password}</p>
              </div>
              <div className='col-md-4' style={{ padding: '0px 10px 0 0' }}>
                <p className='title'>Traking Licence Key</p>
                <p className='data'>{settingData?.traking_licence_key}</p>
              </div>
              <div className='col-md-4' style={{ padding: '0px 10px 0 0' }}>
                <p className='title'>Origin Area</p>
                <p className='data'>{settingData?.origin_area}</p>
              </div>
              <div className='col-md-4' style={{ padding: '0px 10px 0 0' }}>
                <p className='title'>Traking Login Id</p>
                <p className='data'>{settingData?.traking_login_id}</p>
              </div>

            </div>
          </div>
          <div className='col-md-2' style={{ padding: '0px 10px 0 0' }}>
            <p className='title d-flex justify-content-end'>Action</p>
            <p className='data d-flex justify-content-end'>
              <Link className='link' to={''} onClick={() => { setPopup('editCourier', 'bluedart', datasContent) }}>Edit</Link>&nbsp;
              <Link className='link' to={''} onClick={() => { setPopup('deleteCourier', 'bluedart', datasContent) }}><span style={{ color: 'red' }}>Delete</span></Link>
            </p>
          </div>
        </div>
      </div>

      : datasContent?.shipping_carrier_name.toString()?.toLowerCase() === 'other' ?
        <div className='content'>
          <div className='row'>
            <div className='col-md-10'>
              <div className='row' style={{ padding: '0px' }}>
                <div className='col-md-4' style={{ padding: '0px 10px 0 0' }}>
                  <p className='title'>Account Name</p>
                  <p className='data'>{datasContent.display_name === '' ? datasContent?.shipping_carrier_name : datasContent.display_name}</p>
                </div>
                <div className='col-md-4' style={{ padding: '0px 10px 0 0' }}>
                  {/* <p className='title'>Client/Warehouse name</p>
                  <p className='data'>{datasContent.clientName}</p> */}
                </div>
                <div className='col-md-4' style={{ padding: '0px 10px 0 0' }}>
                  {/* <p className='title'> API Key</p>
                  <p className='data'>{settingData?.key}</p> */}
                </div>
              </div>
            </div>
            {/* <div className='col-md-2' style={{ padding: '0px 10px 0 0' }}>
              <p className='title d-flex justify-content-end'>Action</p>
              <p className='data d-flex justify-content-end'>
                <Link className='link' to={''} onClick={() => { setPopup('editCourier', 'other', datasContent) }}>Edit</Link>&nbsp;
                <Link className='link' to={''} onClick={() => { setPopup('deleteCourier', 'other', datasContent) }}><span style={{ color: 'red' }}>Delete</span></Link>
              </p>
            </div> */}
          </div>
        </div>

        : datasContent?.shipping_carrier_name.toString()?.toLowerCase() === 'dtdc' ?
          <div className='content'>
            <div className='row'>
              <div className='col-md-10'>
                <div className='row' style={{ padding: '0px' }}>

                  <div className='col-md-4' style={{ padding: '0px 10px 0 0' }}>
                    <p className='title'>Account Name</p>
                    <p className='data'>{datasContent.display_name === '' ? datasContent?.shipping_carrier_name : datasContent.display_name}</p>
                  </div>
                  <div className='col-md-4' style={{ padding: '0px 10px 0 0' }}>
                    <p className='title'>API Key</p>
                    <p className='data'>{settingData?.key}</p>
                  </div>
                  <div className='col-md-4' style={{ padding: '0px 10px 0 0' }}>
                    <p className='title'>Customer ID</p>
                    <p className='data'>{settingData?.customer_code}</p>
                  </div>

                  <div className='col-md-4' style={{ padding: '0px 10px 0 0' }}>
                    <p className='title'>Reference Number</p>
                    <p className='data'>{settingData?.reference_number}</p>
                  </div>

                  <div className='col-md-4' style={{ padding: '0px 10px 0 0' }}>
                    <p className='title'>Document Type</p>
                    <p className='data'>{settingData?.load_type}</p>
                  </div>
                  <div className='col-md-4' style={{ padding: '0px 10px 0 0' }}>
                    <p className='title'>Service Type</p>
                    <p className='data'>{settingData?.service_type_id}</p>
                  </div>
                  <div className='col-md-4' style={{ padding: '0px 10px 0 0' }}>
                    <p className='title'>cod key</p>
                    <p className='data'>{settingData?.cod_key}</p>
                  </div>
                  <div className='col-md-4' style={{ padding: '0px 10px 0 0' }}>
                    <p className='title'>cod customer code</p>
                    <p className='data'>{settingData?.cod_customer_code}</p>
                  </div>
                  <div className='col-md-4' style={{ padding: '0px 10px 0 0' }}>
                    <p className='title'>Tracking Token</p>
                    <p className='data'>{settingData?.tracking_key}</p>
                  </div>
                </div>
              </div>
              <div className='col-md-2' style={{ padding: '0px 10px 0 0' }}>
                <p className='title d-flex justify-content-end'>Action</p>
                <p className='data d-flex justify-content-end'>
                  <Link className='link' to={''} onClick={() => { setPopup('editCourier', 'dtdc', datasContent) }}>Edit</Link>&nbsp;
                  <Link className='link' to={''} onClick={() => { setPopup('deleteCourier', 'dtdc', datasContent) }}><span style={{ color: 'red' }}>Delete</span></Link>
                </p>
              </div>
            </div>

          </div>
          : datasContent?.shipping_carrier_name.toString()?.toLowerCase() === 'ekart' ?
            <div className='content'>
              <div className='row'>
                <div className='col-md-10'>
                  <div className='row' style={{ padding: '0px' }}>
                    <div className='col-md-4' style={{ padding: '0px 10px 0 0' }}>
                      <p className='title'>Account Name</p>
                      <p className='data'>{datasContent.display_name === '' ? datasContent?.shipping_carrier_name : datasContent.display_name}</p>
                    </div>
                    <div className='col-md-4' style={{ padding: '0px 10px 0 0' }}>
                      <p className='title'>API Key</p>
                      <p className='data'>{settingData?.key}</p>
                    </div>
                    <div className='col-md-4' style={{ padding: '0px 10px 0 0' }}>
                      <p className='title'>Merchant Code</p>
                      <p className='data'>{settingData?.merchant_code}</p>
                    </div>
                    {/* <div className='col-md-4' style={{ padding: '0px 10px 0 0' }}>
                      <p className='title'>Location Code</p>
                      <p className='data'>{settingData?.display_name}</p>
                    </div>
                    <div className='col-md-4' style={{ padding: '0px 10px 0 0' }}>
                      <p className='title'>Goods Category</p>
                      <p className='data'>{settingData?.clientName}</p>
                    </div>
                    <div className='col-md-4' style={{ padding: '0px 10px 0 0' }}>
                      <p className='title'>Service Code</p>
                      <p className='data'>{settingData?.clientName}</p>
                    </div>
                    <div className='col-md-4' style={{ padding: '0px 10px 0 0' }}>
                      <p className='title'>Service Types</p>
                      <p className='data'>{settingData?.display_name}</p>
                    </div>
                    <div className='col-md-4' style={{ padding: '0px 10px 0 0' }}>
                      <p className='title'>Delivery Types</p>
                      <p className='data'>{settingData?.clientName}</p>
                    </div> */}

                  </div>
                </div>
                <div className='col-md-2' style={{ padding: '0px 10px 0 0' }}>
                  <p className='title d-flex justify-content-end'>Action</p>
                  <p className='data d-flex justify-content-end'>
                    <Link className='link' to={''} onClick={() => { setPopup('editCourier', 'ekart', datasContent) }}>Edit</Link>&nbsp;
                    <Link className='link' to={''} onClick={() => { setPopup('deleteCourier', 'ekart', datasContent) }}><span style={{ color: 'red' }}>Delete</span></Link>
                  </p>
                </div>
              </div>

            </div>
            : datasContent?.shipping_carrier_name.toString()?.toLowerCase() === 'pickrr' ?

              <div className='content'>
                <div className='row' style={{ padding: '0px' }}>
                  <div className='col-md-2' style={{ padding: '0px 10px 0 0' }}>
                    <p className='title'>Account Name</p>
                    <p className='data'>{datasContent.display_name === '' ? datasContent?.shipping_carrier_name : datasContent.display_name}</p>
                  </div>
                  <div className='col-md-5' style={{ padding: '0px 10px 0 0' }}>
                    <p className='title'> API Key</p>
                    <p className='data'>{settingData?.key}</p>
                  </div>
                  <div className='col-md-2' style={{ padding: '0px 10px 0 0' }}>
                    <p className='title d-flex justify-content-end'>Action</p>
                    <p className='data d-flex justify-content-end'>
                      <Link className='link' to={''} onClick={() => { setPopup('editCourier', 'pickrr', datasContent) }}>Edit</Link>&nbsp;
                      <Link className='link' to={''} onClick={() => { setPopup('deleteCourier', 'pickrr', datasContent) }}><span style={{ color: 'red' }}>Delete</span></Link>
                    </p>
                  </div>
                </div>
              </div>
              : datasContent?.shipping_carrier_name.toString()?.toLowerCase() === 'xpressbees' ?

                <div className='content'>
                  <div className='row' style={{ padding: '0px' }}>
                    <div className='col-md-2' style={{ padding: '0px 10px 0 0' }}>
                      <p className='title'>Account Name</p>
                      <p className='data'>{datasContent?.display_name === '' ? datasContent?.shipping_carrier_name : datasContent.display_name}</p>
                    </div>
                    <div className='col-md-3' style={{ padding: '0px 10px 0 0' }}>
                      <p className='title'>User Name</p>
                      <p className='data'>{settingData?.username}</p>
                    </div>
                    <div className='col-md-5' style={{ padding: '0px 10px 0 0' }}>
                      <p className='title'> Password</p>
                      <p className='data'>{settingData?.password}</p>
                    </div>
                    <div className='col-md-2' style={{ padding: '0px 10px 0 0' }}>
                      <p className='title d-flex justify-content-end'>Action</p>
                      <p className='data d-flex justify-content-end'>
                        <Link className='link' to={''} onClick={() => { setPopup('editCourier', 'xpressbees', datasContent) }}>Edit</Link>&nbsp;
                        <Link className='link' to={''} onClick={() => { setPopup('deleteCourier', 'xpressbees', datasContent) }}><span style={{ color: 'red' }}>Delete</span></Link>
                      </p>
                    </div>
                  </div>
                </div> : datasContent?.shipping_carrier_name.toString()?.toLowerCase() === 'ecomexpress' ?

                  <div className='content'>
                    <div className='row' style={{ padding: '0px' }}>
                      <div className='col-md-2' style={{ padding: '0px 10px 0 0' }}>
                        <p className='title'>Account Name</p>
                        <p className='data'>{datasContent?.display_name === '' ? datasContent?.shipping_carrier_name : datasContent.display_name}</p>
                      </div>
                      <div className='col-md-3' style={{ padding: '0px 10px 0 0' }}>
                        <p className='title'>User Name</p>
                        <p className='data'>{settingData?.username}</p>
                      </div>
                      <div className='col-md-5' style={{ padding: '0px 10px 0 0' }}>
                        <p className='title'> Password</p>
                        <p className='data'>{settingData?.password}</p>
                      </div>
                      <div className='col-md-2' style={{ padding: '0px 10px 0 0' }}>
                        <p className='title d-flex justify-content-end'>Action</p>
                        <p className='data d-flex justify-content-end'>
                          <Link className='link' to={''} onClick={() => { setPopup('editCourier', 'ecomexpress', datasContent) }}>Edit</Link>&nbsp;
                          <Link className='link' to={''} onClick={() => { setPopup('deleteCourier', 'ecomexpress', datasContent) }}><span style={{ color: 'red' }}>Delete</span></Link>
                        </p>
                      </div>
                    </div>
                  </div> : datasContent?.shipping_carrier_name.toString()?.toLowerCase() === 'amazon' ?

                    <div className='content'>
                      <div className='row' style={{ padding: '0px' }}>
                        <div className='col-md-10' style={{ padding: '0px 10px 0 0' }}>
                          <p className='title'>Account Name</p>
                          <p className='data'>{datasContent?.display_name === '' ? datasContent?.shipping_carrier_name : datasContent.display_name}</p>
                        </div>

                        <div className='col-md-2' style={{ padding: '0px 10px 0 0' }}>
                          <p className='title d-flex justify-content-end'>Action</p>
                          <p className='data d-flex justify-content-end'>
                            <Link className='link' to={''} onClick={() => { setPopup('editCourier', 'amazon', datasContent) }}>Edit</Link>&nbsp;
                            <Link className='link' to={''} onClick={() => { setPopup('deleteCourier', 'amazon', datasContent) }}><span style={{ color: 'red' }}>Delete</span></Link>
                          </p>
                        </div>
                      </div>

                      <div className='row' >
                        <div className='col-md-12' style={{ padding: '0px', marginTop: '10px' }} >
                          <p className='title'>Client Id</p>
                          <p className='data'>{settingData?.clientId}</p>
                        </div>
                      </div>


                      <div className='row' >
                        <div className='col-md-12' style={{ padding: '0px', marginTop: '10px' }}>
                          <p className='title'>Client Secret</p>
                          <p className='data'>{settingData?.clientSecret}</p>
                        </div>
                      </div>
                      <div className='row' >
                        <div className='col-md-12' style={{ padding: '0px', marginTop: '10px' }}>
                          <p className='title'>RefreshToken</p>
                          <p className='data text-break'>{settingData?.refreshToken}</p>
                        </div>
                      </div>
                    </div> : '';

  return (
    <>
      <Loader showHide={loader} />
      {check}
      {popupData.popupName !== "" &&
        <CourierPopup
          item={popupData.data}
          show={popupData.popupName}
          cType={popupData.courierType}
          handleSave={popupData.callBackMethod}
          handleClose={closePopup}
        />
      }
    </>
  )
}

export default Contents
